import React, { cloneElement } from 'react';

import { Caption } from 'components/caption/Caption';
import { Map, TRegions } from 'components/map/Map';

import s from './GalleryItem.scss';

interface IProps {
  image: React.ReactElement<any>;
  quote?: string;
  caption?: string;
  credits?: string;
  region?: TRegions;
  isOpen?: boolean;
}

export const GalleryItem = ({ image, quote, caption, credits, region }: IProps) => {

  return (
    <div className={s.galleryItem}>
      <div className={s.galleryItem__imageWrap}>
        {cloneElement(image, {
          className: s.galleryItem__image,
          preserveRatio: false,
        })}
      </div>
      <div className={s.galleryItem__content}>
        {quote && (
          <div className={s.galleryItem__quoteWrap}>
            <q className={s.galleryItem__quote}>{quote}</q>
          </div>
        )}
        {region && (
          <div className={s.galleryItem__map}>
            <Map size="small" region={region} />
          </div>
        )}
        {(caption || credits) && <Caption caption={caption} credits={credits} align="left" />}
      </div>
    </div>
  );
};
