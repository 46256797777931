import React, { Children, useState, useEffect, useRef, useContext, cloneElement } from 'react';
import { Helmet } from 'react-helmet';
import { SliceContext } from 'context/slices';

import { useKeyDown } from 'hooks/use-keydown';

import { CloseButton } from 'components/close-button/CloseButton';
import { CarouselButton } from 'components/carousel-button/CarouselButton';

import s from './Gallery.scss';

interface IProps {
  children: React.ReactNode;
  isOpen?: boolean;
  galleryImages: any;
}

export const Gallery = ({ children, galleryImages }: IProps) => {
  const {
    activeGalleryImage,
    setActiveGalleryImage,
    info,
  } = useContext<any>(SliceContext);
  const buttonClose = useRef<HTMLButtonElement>(null);
  const nextImageButton = useRef<HTMLButtonElement>(null);
  const slides = Children.toArray(children);
  const keys = useKeyDown();
  const [activeImage, setActiveImage] = useState(-1);
  const [imageData, setImageData] = useState<any>(undefined);

  const slidesCount = slides.length - 1;
  const isOpen = (activeImage! >= 0);

  useEffect(() => {
    if (!isOpen) { return; }

    // on arrow right press
    if (keys.includes(39)) { nextImage(); }
    // on arrow left press
    if (keys.includes(37)) { prevImage(); }
    // on esc press
    if (keys.includes(27)) { closeModal(); }

  }, [keys]);

  // if opening gallery set focus on button next image
  useEffect(() => {
    if (isOpen && nextImageButton.current !== null) {
      nextImageButton.current.focus();
    }
  }, [isOpen]);

  // finds index of the active image that should appear in the gallery
  useEffect(() => {
    setActiveImage(galleryImages.findIndex((el: any) => el.url === activeGalleryImage));
  }, [activeGalleryImage]);

  // finds the active image data
  useEffect(() => {
    const activeChild = slides[activeImage!] as React.ReactElement<any>;
    const activeImageUrl = activeChild && activeChild.props.image && activeChild.props.image.props.image.url;
    const data = info.find((e: any) => {
      return e.url === activeImageUrl;
    });

    setImageData(data);
  }, [activeImage]);

  // set focus on close button
  const focusFirstEl = () => {
    if (buttonClose.current !== null) {
      buttonClose.current.focus();
    }
  };

  const nextImage = () => {
    const nextImageIndex =
      activeImage === slidesCount ? 0 : activeImage + 1;

    setActiveImage(nextImageIndex);
  };

  const prevImage = () => {
    const prevImageIndex = activeImage === 0
      ? slidesCount
      : activeImage - 1;

    setActiveImage(prevImageIndex);
  };

  const closeModal = () => {
    setActiveImage(-1);
    setActiveGalleryImage(undefined);
  };

  return (
    <>
      {isOpen && (<Helmet htmlAttributes={{ class: 'scroll-disabled' }} />)}
      <div
        className={s('gallery', { open: isOpen })}
        role="dialog"
        aria-modal={isOpen}
        aria-label="Image gallery"
      >
        <div className={s.gallery__close}>
          <CloseButton
            ref={buttonClose}
            onClick={closeModal}
          />
        </div>
        <div className={s.gallery__inner}>
          {isOpen && cloneElement(slides[activeImage!] as React.ReactElement<any>, {
            region: imageData && imageData.region,
            quote: imageData && imageData.quote,
            caption: imageData && imageData.caption,
            credits: imageData && imageData.credits,
          })}
        </div>

        <div className={s.gallery__buttonWrap}>
          <div className={s.gallery__button}>
            <CarouselButton
              direction="previous"
              onClick={prevImage}
            />
          </div>
          <div className={s.gallery__button}>
            <CarouselButton
              direction="next"
              onClick={nextImage}
              ref={nextImageButton}
            />
          </div>
          <button
            className={s.gallery__buttonHidden}
            onFocus={focusFirstEl}
          />
        </div>
      </div>
    </>
  );
};

Gallery.defaultProps = {
  isOpen: true,
};
