/* wrapper for content templates, mainly just used to position social stuff */
import React from 'react';

import s from './Content.scss';

interface IContentProps {
  children: React.ReactNode;
  social?: React.ReactElement<any>;
}

export const Content = ({ children }: IContentProps) => (
  <article className={s('content')} aria-label="Main article">
    {children}
  </article>
);
