import React from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty, pickBy, flatten } from 'lodash';
import { FormattedMessage } from 'react-intl';

import createLink from 'utils/createLink';
import { linkResolver } from 'utils/linkResolver';

import { MetaContainer } from 'containers/meta/MetaContainer';
import { SocialContainer } from 'containers/social/Social';
import { Slices } from 'containers/slices/Slices';

import RichText from 'components/rich-text/RichText';
import { Image } from 'components/image/Image';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

import { ArticleHero } from 'components/article-hero/ArticleHero';
import { ArticleHeroItem } from 'components/article-hero/ArticleHeroItem';

import { Gallery } from 'components/gallery/Gallery';
import { GalleryItem } from 'components/gallery/GalleryItem';
import { SliceProvider } from 'context/slices';
import { Content } from 'components/content/Content';
import { Carousel } from 'components/carousel/Carousel';
import { UtilityCard } from 'components/utility-card/UtilityCard';
import { Flowbox } from 'components/flowbox/Flowbox';
import { Container } from 'components/layout/Container';
import { Segment } from 'components/layout/Segment';

import {
  fragmentArticleBanner,
  fragmentImageRegion,
  fragmentMeta,
  fragmentLink,
} from 'fragments';

interface IProps {
  data: any;
  pageContext: {
    uid: string;
    lang: string;
  };
}

const Article = (props: IProps) => {
  const {
    data: { prismic = null } = {},
    pageContext: { lang },
  } = props;

  if (!prismic) {
    console.error('Article does not have prismic prop'); // tslint:disable-line
  }

  const pageData = get(prismic, 'article', []);
  const sliceData = get(pageData, 'body', []) || []; // body can be null

  const prevArticle = get(prismic, 'prev.edges', []);
  const nextArticle = get(prismic, 'next.edges', []);

  /*
  const allChildrenArticle = get(prismic, 'children.edges', []);
  const childrenArticles = allChildrenArticle
    .map((item: any) => item.node)
    .filter((item: any) => item._meta && item._meta.lang && item._meta.lang === lang);
  */

  const allSimilarArticles = get(prismic, 'similar.edges', []);
  const similarArticles = allSimilarArticles
    .map((item: any) => item.node)
    .filter((item: any) => item._meta.lang === lang);

  const heroItems = flatten([nextArticle, prevArticle]);

  const galleryImages = (sliceData || []).reduce(
    (arr: any, { primary }: any) => {
      // Picks up image fields in slices that includes gallery_1x proc from prismic
      const imageSlices = pickBy(
        primary,
        (val, key) =>
          key.indexOf('image') !== -1 &&
          !isEmpty(val) &&
          !isEmpty(val.gallery_1x),
      );

      return [...arr, ...Object.values(imageSlices)];
    },
    [],
  );

  const renderHeroItem = ({ node }: any, i: number) => {
    if (isEmpty(node) || isEmpty(node.image) || isEmpty(node._meta)) {
      return;
    }

    const imageUrl = !isEmpty(node.image.hero)
      ? get(node, 'image.hero.url', '')
      : '';
    const imageCaption =
      RichText.asText(node.image_caption) || get(node, 'image.alt', '') || '';
    const imageCredits =
      RichText.asText(node.image_author) || get(node, 'image.copyright', '');
    const kicker = !isEmpty(node.category)
      ? RichText.asText(node.category.title)
      : undefined;
    const heading = RichText.asText(node.title);

    // We might have the same item as previous and next item if there are one or two items in the
    // category, so the key needs to be made unique via index
    return (
      <ArticleHeroItem
        key={`${node._meta.uid}-${i}`}
        link={createLink(node)}
        heading={heading}
        kicker={kicker}
        imageCaption={imageCaption}
        imageCredits={imageCredits}
        image={<Image src={imageUrl} alt="" />}
      />
    );
  };

  // Shown on the https://visiticeland.com/article/geothermal-pools
  const showFlowbox = pageData._meta.id === 'XJy_JxAAADrUnVmy'

  const formattedHeading = <FormattedMessage id="get-inspired" />
  return (
    <SliceProvider>
      <MetaContainer data={pageData} />

      <ArticleHero>
        <ArticleHeroItem
          key={pageData._meta.uid}
          link={createLink(pageData)}
          heading={RichText.asText(pageData.title)}
          kicker={
            !isEmpty(pageData.category)
              ? RichText.asText(pageData.category.title)
              : undefined
          }
          imageCaption={
            RichText.asText(pageData.image_caption) ||
            get(pageData, 'image.alt', '') ||
            ''
          }
          imageCredits={
            RichText.asText(pageData.image_author) ||
            get(pageData, 'image.copyright', '')
          }
          image={
            <Image src={get(pageData, 'image.hero.url', undefined)} alt="" /> ||
            undefined
          }
          video={get(pageData, 'video.url', '')}
          poster={get(pageData, 'image.hero.url', undefined)}
          youtubeID={RichText.asText(pageData.youtube_id)}
        />
        {!isEmpty(heroItems) && heroItems.map(renderHeroItem)}
      </ArticleHero>


      <Content>
        <SocialContainer
          type="sidebar"
          facebookLink="/"
          twitterLink="/"
          pinterestLink="/"
        />

        {!isEmpty(galleryImages) && (
          <>
            <Gallery galleryImages={galleryImages}>
              {galleryImages.map((item: any, i: number) => (
                <GalleryItem
                  key={i}
                  image={<PrismicImage image={item} view="gallery_1x" />}
                />
              ))}
            </Gallery>
          </>
        )}

        {!isEmpty(sliceData) && <Slices data={sliceData} />}
      </Content>

      {showFlowbox && (
        <Container>
          <Segment>
            <Flowbox flowboxKey="G24FII8tQ_2mribBKchGnQ" tags={['geothermalpool']} />
          </Segment>
        </Container>
      )}

      <Content>
        {similarArticles.length > 0 && (
          <Carousel heading={formattedHeading}>
            {similarArticles.map((item: any, i: number) => {
              const heading = item.title
                ? RichText.asText(item.title)
                : undefined;
              const category =
                item.category && item.category.title
                  ? RichText.asText(item.category.title)
                  : undefined;
              const image = item.image
                ? get(item, 'image.hero.url', undefined)
                : null;

              return (
                <UtilityCard
                  key={i}
                  heading={heading}
                  category={category}
                  link={linkResolver(item._meta)}
                >
                  {image && <Image src={image} width={400} height={300} />}
                </UtilityCard>
              );
            })}
          </Carousel>
        )}
      </Content>
    </SliceProvider>
  );
};

export const query = graphql`
  query Article(
    $uid: String!
    $id: String!
    $lang: String!
    $next: String = ""
    $prev: String = ""
    $children: [String!] = ""
  ) {
    prismic {
      similar: allArticles(similar: { documentId: $id, max: 6 }) {
        edges {
          node {
            ...articleBanner
          }
        }
      }
      article(uid: $uid, lang: $lang) {
        _linkType
        __typename
        _meta {
          ...meta
        }
        title
        description
        image
        image_author
        image_caption
        seo_title
        seo_description
        seo_image
        youtube_id
        video {
          _linkType
          __typename
          ... on PRISMIC__FileLink {
            url
          }
        }
        category {
          ... on PRISMIC_Article_category {
            title
          }
        }
        body {
          ... on PRISMIC_ArticleBodyCta_carousel {
            type
            fields {
              cta_carousel_image
              cta_carousel_kicker
              cta_carousel_kicker_image
              cta_carousel_heading
              cta_carousel_text
              cta_carousel_link {
                ...link
              }
            }
          }
          ... on PRISMIC_ArticleBodyRich_text {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_ArticleBodyEmbed_video {
            type
            primary {
              embed
              embed_placeholder
            }
          }
          ... on PRISMIC_ArticleBodyTwo_col_heading_text {
            type
            primary {
              heading
              text
            }
          }
          ... on PRISMIC_ArticleBodyLarge_list_item {
            type
            primary {
              list_title
              list_text
              list_image
              list_image_number
              list_image_caption
              list_image_author
              list_map
              list_available
            }
          }
          ... on PRISMIC_ArticleBodyPhoto_grid {
            type
            primary {
              grid_large_image
              grid_large_caption
              grid_large_author
              grid_large_region {
                ...imageRegion
              }
              grid_large_quote
              grid_image_position
              grid_medium_image
              grid_medium_caption
              grid_medium_author
              grid_medium_quote
              grid_medium_region {
                ...imageRegion
              }
              grid_small_image
              grid_small_caption
              grid_small_author
              grid_small_quote
              grid_small_region {
                ...imageRegion
              }
              grid_pair_alignment
            }
          }
          ... on PRISMIC_ArticleBodyIntro {
            type
            primary {
              intro_text
              intro_separator
            }
          }
          ... on PRISMIC_ArticleBodyArticle_cta {
            type
            primary {
              cta_title
              cta_image
              link_text
              cta_link {
                ...link
              }
            }
          }
          ... on PRISMIC_ArticleBodyTwo_col_copy {
            type
            primary {
              copy_col_1_text
              copy_col_1_image
              copy_col_1_image_caption
              copy_col_1_image_author
              copy_col_1_image_quote
              copy_col_1_image_region {
                ...imageRegion
              }
              copy_col_1_quote
              copy_col_2_text
              copy_col_2_image
              copy_col_2_image_caption
              copy_col_2_image_author
              copy_col_2_image_quote
              copy_col_2_image_region {
                ...imageRegion
              }
            }
          }
          ... on PRISMIC_ArticleBodySubheader_with_map {
            type
            primary {
              subheader_title
              subheader_map
              subheader_available
              show_maps_labels
            }
            fields {
              subheader_map_regions {
                ... on PRISMIC_Region {
                  title
                  _meta {
                    ...meta
                  }
                }
              }
            }
          }
          ... on PRISMIC_ArticleBodyMap_list {
            type
            primary {
              map_title
              map_center
              map_zoom
            }
            fields {
              map_item_image
              map_item_title
              map_item_subtitle
              map_item_geo_location
            }
          }
          ... on PRISMIC_ArticleBodyFullscreen_video {
            type
            primary {
              fs_title
              fs_kicker
              fs_image
              fs_image_author
              fs_image_caption
              fs_youtube_id
              fs_video {
                _linkType
                __typename
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_ArticleBodyArticle_cta_button {
            type
            primary {
              cta_button_text
              cta_button_show_arrow
              cta_button_link {
                ...link
              }
            }
          }
          ... on PRISMIC_ArticleBodyPartners {
            type
            primary {
              partner_heading
              partner_alignment
            }
            fields {
              partner {
                ... on PRISMIC_Partner {
                  name
                  image
                  link {
                    ...link
                  }
                }
              }
            }
          }
        }
      }
      prev: _allDocuments(id: $prev) {
        edges {
          node {
            ... on PRISMIC_Article {
              ...articleBanner
            }
            ... on PRISMIC_Page_content {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Festivals {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Flights_to_iceland {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
          }
        }
      }
      next: _allDocuments(id: $next) {
        edges {
          node {
            ... on PRISMIC_Article {
              ...articleBanner
            }
            ... on PRISMIC_Page_content {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Festivals {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Flights_to_iceland {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
          }
        }
      }
      children: _allDocuments(id_in: $children) {
        edges {
          node {
            ... on PRISMIC_Article {
              ...articleBanner
            }
            ... on PRISMIC_Page_content {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Festivals {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
            ... on PRISMIC_Flights_to_iceland {
              _meta {
                ...meta
              }
              title
              image
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

Article.fragments = [
  fragmentArticleBanner,
  fragmentMeta,
  fragmentImageRegion,
  fragmentLink,
];

export default Article;
